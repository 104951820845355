import React from "react"
import Layout from '../layout'
import SEO from '../components/seo'
import { G } from '../styles/components'
import content from '../../data/content'
import tool from '../images/tool.png'
import About from '../components/about' 
import Repair from '../components/repair'

export default () => {
    const { 
        services:{
            pdr
        }
    } = content;
 
    return(
        <Layout>
            <SEO title={'Services'}/>
            <G.Title>Over 20+ years professional dent removal experience!</G.Title>
            <About title={pdr.title} text={pdr.text} img={tool}/>
            <Repair />
        </Layout>
    )
}