import styled from 'styled-components'
import { device, colors } from '../../styles/variables'

export const S = {};

S.Navigation = styled.nav`
    width:100%;
    height:65px;
    background-color:black;
    position:fixed;
    @media ${device.laptop}{
        span, input{
            display:none;
        }
        ul{
            transform: none
        }
    }
`

S.Ul = styled.ul`
    position: absolute;
    width:100%;
    height:500px;
    top:0;
    margin: 0;
    padding: 0;
    padding-top: 55px;
    background: black;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;  
    transform-origin: 0% 0%;
    transform: translate( -100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    @media ${device.laptop}{
        display:flex;
        justify-content:center;
        height:65px;
        padding:0;
    }
`

S.Li = styled.li`
    padding: 20px 0;
    font-size: 20px;
    margin:auto;
    list-style:none;
    text-align:center;
    text-transform:uppercase;
    color: ${colors.white};
    :hover{
        color: ${colors.blue};
    }
    @media ${device.laptop}{
        padding: 20px;
        width:130px;
    }
`

S.Menu = styled.input`
    cursor: pointer;
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    margin:0;
    top: 15px;
    right: 1rem;
    opacity: 0; /* Make it hidden*/
    z-index: 2; /* Place it over the hamburger */
    :checked ~ span{
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: white;
    }
    :checked ~ span:nth-last-child(2){
        transform: rotate(-45deg) translate(0, -1px);
    }
    :checked ~ span:nth-last-child(3){
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    :checked ~ ul{
        transform: none;
    }
`

S.Bar = styled.span`
    display: block;
    width: 35px;
    height: 4px;
    top:22px;
    right: 1rem;
    margin-left:auto;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;

    :first-child{
        transform-origin: 0% 0%;
    }
    :nth-last-child(2){
        transform-origin: 0% 100%;
    }
`
S.Logo = styled.img`
    display:block;
    width: 300px;
    height: 100px;
    margin:0 auto;
    @media ${device.laptop}{
        height:65px;
        width:175px;
        margin:0;
        margin-right:4rem;
    }
`
S.NavLogo = styled.img`
    width: 175px;
    height: 65px;
    margin-left:1rem;
    position: absolute;

    @media ${device.laptop}{
        display:none;
    }
`

S.Button = styled.button`
    cursor: pointer;
    background-color: ${colors.blue};
    border: 0px solid transparent;
    text-decoration: none;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.white};
    margin-top:15px;
    height:35px;
    width:120px;
    display:none;
    margin-left:4rem;
    :hover{
        border: 2px solid white;
        height:39px;
        margin-top:13px;
    }
    @media ${device.laptop}{display:block;}
`
S.NavButton = styled.button`
    cursor: pointer;
    background-color: ${colors.blue};
    border: 0px solid transparent;
    text-decoration: none;
    border-radius: 10px;
    font-size: 18px;
    position:absolute;
    top:13px;
    left:1rem;
    align-items: center;
    text-align: center;
    color: ${colors.white};
    height:35px;
    width:120px;
    border: 2px solid white;

    @media ${device.tablet}{
        display:none;
        }
`