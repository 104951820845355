const content = {
    home:{
        header: `Straightening the world one dent at a time since 1996!`,
        about:{
            title: `Andy's Dent Works`,
            text: `Located in the heart of the Texas hill country, Andy's Dent Works is the only option for bringing your car back to it's original dent-free condition. With more than 20 years of professional dent repair experience and having worked on thousands of dents, Andy can help get your car back to looking it's best.`
        },
        removal:{
            title: `DENT REMOVAL PROCESS`,
            subtitle: `Preserves the integrity of the original paint`,
            items:[
                {
                    title: `INSPECTION`,
                    text: `It starts with the rigorous quality control inspection to ensure that all dents have been located and repair work can be performed.`,
                    img: `src/images/inspection.png`
                },
                {
                    title: `DENT REPAIR`,
                    text: `After the evaluation, Andy begins the dent removal process carefully performing the dent repair work.`,
                    img: `src/images/repair.png`
                },
                {
                    title: `DELIVERY`,
                    text: `Once the vehicle is ready, you'll be contacted for the final inspection and documentation.`,
                    img: `src/images/delivery.png`
                },
            ]
        },
        testmonials:{
            title: `TESTIMONIALS`,
            subtitle: `This is what people are saying about us!`,
            items:[
                {
                    title: `- John S.`,
                    text: `'Whoa! What can I say, he's amazing at what he does'` 
                },
                {
                    title: `- David M.`,
                    text: `'Andy went above and beyond our expectations'`                
                },
                {
                    title: `- Chris F.`,
                    text: `'Hands down the best dent repair in San Antonio'`                   
                },
            ]
        },
        questions:{
            title:`QUESTIONS / DIRECTIONS`,
            buttons:[
                {
                    title: `Call Today`
                },
                {
                    title: `Location`
                },
            ]
        }
    },
    services:{
        header:``,
        pdr:{
            title:`What is paintless dent repair?`,
            text:`Paintless dent repair, also known as paintless dent removal, describes a method of removing minor dents from the body of a motor vehicle. A wide range of damage can be repaired using PDR as long as the paint surface is intact. PDR may be used on both aluminum and steel panels. The most common practical use for PDR is the repair of hail damage, door dings, minor creases, large dents and bodylines damage. The method can also be utilized to prepare a damaged panel for repainting by minimizing the use of body filler. This technique is currently known as 'push to paint' or 'push for paint'.`
        },
        services:[
            {
                title:`Hail Damage Repair`,
                text:`Hail is common in Texas and can happen at any time, making your vehicle look unsightly. Andy's Dent Works can work with insurance companies to get your car back in tip top shape.`,
                img: require(`../src/images/hail.png`)
            },
            {
                title:`Paintless Dent Repair`,
                text:`Dents come in all shapes and sizes and can happen almost anywhere. A great looking vehicle with some dents can be uncomfortable, but they dont have to stay. Come by to get your vehicle inspected and get back on the road knowing it's in the best condition.`,
                img: require(`../src/images/dent.jpg`)
            }
        ]
    },
    contact:{
        header:`HOW TO FIND US`,
        directions:{
            title:`Directions / Contact Info`,
            directions:[
                {
                    text:`Head north on I-10 W.`
                },
                {
                    text:`Take exit 543 and merge onto Frontage Rd.`
                },
                {
                    text:`Turn left onto Scenic Loop Rd.`
                },
                {
                    text:`Travel for 0.3 mi.`
                },
                {
                    text:`Destination will be on the left.`
                }
            ],
            contact:[
                {
                    text:`31445 Frontage Rd, Boerne, TX 78006`
                },
                {
                    text:`andydents@gmail.com`
                },
                {
                    text:`210 - 454 - 2719`
                },
                {
                    text:`Monday - Saturday 9 am - 5 pm`
                }
            ]
        },
        faq:{
            title:`FREQUENTLY ASKED QUESTIONS`,
            subtitle:`These are some of the questions we get asked most often. If you have a specific question, feel free to give us a call or send an email.`,
            items:[
                {
                    title:`WHY PDR?`,
                    text:`As long as PDR can be performed, its a fraction of the cost compared to the body shop. Preserves the integrity of the original paint on the vehicle. Repair work can usually be performed in the same day, excluding hail.`
                },
                {
                    title:`WILL THE DENTS RETURN?`,
                    text:`No, once the repair work has been performed, the body will remain in its repaired condition.`
                },
                {
                    title:`DO YOU FIX SCRATCHES?`,
                    text:`We only repair the dents without effecting the paint. A detail shop or vendors that specialize in painting small portions of a car (bumpers, spot blending, etc)`
                }
            ]
        }
    },        
    footer:{
        items:[
            {
                title:`CONTACT`,
                text:{
                    phone:`Phone: 210-454-2719`,
                    email:`Email: andydents@gmail.com`
                }
            },
            {
                title:`HOURS`,
                text:`Monday - Saturday 9 am - 5 pm`
            },
            {
                title:`LOCATION`,
                text:`31445 Frontage Rd, Boerne, TX 78006`
            }
        ],
        copy:`© 2019 - Andy’s Dent Works - Professional Paintless Dent Repair`
    },
    header:{
        title:`PROFESSIONAL PAINTLESS DENT REPAIR`,
        subtitle:`REMOVING DENTS, DINGS AND HAIL DAMAGE!`
    }
}
module.exports = content