import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../../graphql/useMetadata'

export default ({ title, description, pathname}) => {
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url:`${siteUrl}${pathname || '/'}`,
  }
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} />
  )
}