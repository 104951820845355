import styled from 'styled-components'
import { device } from '../../styles/variables'

export const S = {};

S.Background = styled.div`
    background: url(${props => props.url}) black;
    background-position: 50% 50%;
    background-size: cover;
    height: 200px;
    margin-top: 65px;
`

S.Title = styled.h1`
    font-size: 20px;
    padding-top: 50px;
`

S.SubTitle = styled.h3`
    font-size: 12px;
    padding: 1rem;
`


S.Header = styled.header`
    h1, 
    h3{
        text-transform: uppercase;
        text-align: center;
        color: white;
        margin: 0;
    }
    @media ${device.mobileL}{
        ${S.Title}{
            font-size: 28px;
        }
        ${S.SubTitle}{
            font-size: 16px;
        }
    }
    @media ${device.tablet}{
        ${S.Background}{
            height: 300px;
        }
        ${S.Title}{
            padding-top: 100px;
            font-size: 34px;
        }
        ${S.SubTitle}{
            font-size: 22px;
        }
    }
    @media ${device.laptop}{
        ${S.Background}{
            height: 350px;
        }
        ${S.Title}{
            padding-top: 130px;
            font-size: 40px;
        }
        ${S.SubTitle}{
            font-size: 26px;
        }
    }
`