import styled from 'styled-components'
import { colors } from './variables'
export const G = {};

G.Container = styled.section`
    background-color: ${props => props.white ? '#FFF': '#EEE'};
`
G.Title = styled.h1`
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
`
G.Questions = styled.section`
    h1{
        padding:1rem;
    }
    text-align:center;
`
G.ButtonContainer = styled.div`
    display:flex;
    flex-flow: row wrap;
    justify-content:center;
`

G.Button = styled.button`
    background: ${colors.blue};
    border: 0px solid transparent;
    text-decoration: none;
    border-radius: 10px;
    height:35px;
    width:150px;
    font-size: 18px;
    color: ${colors.white};
    margin:2rem;
`