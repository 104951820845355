import React from "react"
import { S } from './style'
import logo from '../../images/logo.svg'

export default () => (
    <S.Footer>
        <S.Container>
            <S.Box >
                <S.Title>CONTACT</S.Title>
                <S.Text>Andy's Dent Works</S.Text>
                <a href="tel:2104542719"><S.Text>Phone: 210-454-2719</S.Text></a>
                <a href="mailto:andydents@gmail.com"><S.Text>Email: andydents@gmail.com</S.Text></a>
                
            </S.Box >
            <S.Box >
                <S.Title>HOURS</S.Title>
                <S.Text>Monday - Saturday</S.Text>
                <S.Text>9 am - 5 pm</S.Text>
            </S.Box >
            <S.Box >
                <S.Title>LOCATION</S.Title>
                <a href='https://www.google.com/maps/dir//29.7499313,-98.7052394/@29.7498133,-98.7061012,402m/data=!3m1!1e3!4m2!4m1!3e0'><S.Text>31445 Frontage Rd, Boerne, TX 78006</S.Text></a>
                
            </S.Box >
        </S.Container>
        <S.Logo src={logo}/>
        <S.Text blue>© 2019 - Andy’s Dent Works - Professional Paintless Dent Repair</S.Text>
    </S.Footer>
)
