import styled from 'styled-components'
import { device } from '../../styles/variables'

export const S = {};

S.Footer = styled.footer`
    display:grid;
    background: linear-gradient(180deg, #1A1A1A 0%, #525252 100%);
`
S.Container = styled.div`
    margin-top: 1em;
    display:grid;
    grid-template-rows:repeat(3, 1fr);
    max-width:900px;
    margin-left: auto;
    margin-right: auto;
    @media ${device.tablet}{
    grid-template-columns:repeat(3, 1fr);
    grid-template-rows:none;

}
`
S.Box = styled.div``

S.Logo = styled.img`
    width: 280px;
    height: 110px;
    margin-bottom:2em;
    margin-left: auto;
    margin-right: auto;
    @media ${device.tablet}{
        margin-top:2em;
    }
`
S.Title = styled.h3`
    text-align:center;
    color:#FFF;
    `
S.Text = styled.p`
    padding: 0 1rem;
    text-align:center;
    color:${props => props.blue ? '#FFF' : '#00C2FF'};
    @media ${device.tablet}{
        margin-right:0;
        margin-left:0;
    }
`