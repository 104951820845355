import { graphql, useStaticQuery} from 'gatsby'

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(graphql`
        query{
            site{
                pathPrefix
                siteMetadata{
                    defaultTitle:title
                    titleTemplate
                    defaultDescription:description
                    siteUrl
                    author
                    menuLinks {
                        name
                        link
                    }
                }
            }
        }
    `)
    return site.siteMetadata
} 