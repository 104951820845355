import styled from 'styled-components'
import {device} from '../../styles/variables'

export const S = {};

S.Wrapper = styled.div`
    display: grid;
    justify-content:center;
    align-content:center;
    padding: 1rem;

    p{
        height:130px;
    }
    h1{
        border-bottom: 1px solid #00C2FF;
        padding-bottom:10px;
    }
    @media ${device.tablet}{
       grid-template-columns: repeat(2, 1fr);
       grid-gap:2rem;
       padding: 2rem;
   }
   @media ${device.laptop}{
    grid-template-columns: repeat(2, auto);
       grid-gap: 100px;
   }
`
S.Container = styled.section`
    max-width:400px;
    margin-left:auto;
    margin-right:auto;
`

S.Img = styled.img`
    width:100%;
    max-width:400px;
`