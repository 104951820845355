import {css} from 'styled-components'

export const reset = css`
    html {
        -webkit-font-smoothing: auto;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        font-family: sans-serif;
        box-sizing:border-box;
    }
    body {
        margin:0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    a {
        text-decoration: none;
    }
    a:hover {
        cursor: pointer;
    }
    textarea {
        resize: none;
    }
`