import styled from 'styled-components'
import {device} from '../../styles/variables'

export const S = {};

S.Heading = styled.section`
    max-width:400px;
`

S.Img = styled.img`
    width:100%;
    max-width:400px;
    border: 2px solid white;
`

S.Wrapper = styled.div`
    display: grid;
    justify-content:center;
    align-content:center;
    background-color:#f1f1f1;
    padding: 1rem;
    
    h1{
        border-bottom: 1px solid #00C2FF;
        padding-bottom:10px;
    }
   @media ${device.tablet}{
       grid-template-columns: repeat(2, auto);
       grid-gap:2rem;
       padding: 2rem;
   }
   @media ${device.laptop}{
       grid-gap: 100px;
   }
`