/* Containers */

const sizes = {
    mobileS: `320px`,
    mobileM: `375px`,
    mobileL: `425px`,
    tablet: `768px`,
    laptop: `1024px`,
    desktop: `1440px`,
}

export const device = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    desktop: `(min-width: ${sizes.desktop})`,
}

/* Colors */

export const colors = {
    white:`#FFFFFF`,
    blue:`#00C2FF`,
    border:`#CCC`,
    black:`#000`,
    box:`#EEE`,
}

/* Typography */

// Body Font
export const body = {
    font_size:`1rem`,
    body_font_size:`1.2rem`,
    body_font_style:`normal`,
    body_font_weight:`normal`,
    line_height:`1.6`,
}
// Heading Font
export const heading = {
    h1: `2.25rem`,
    h2: `2rem`,
    h3: `1.75rem`,
    h4: `1.5rem`,
    h5: `1.3rem`
}
export const heading_mobile = {
    h1: `1.75rem`,
    h2: `1.5rem`,
    h3: `1.25rem`,
    h4: `1.1rem`,
    h5: `1rem`
}

/* Padding */
export const padding = {
    padding:`60px`,
    margin: `2rem`,
    content_padding: `60px 0`,
    content_padding_mobile: `30px 0`
}
/* Borders */
export const borders = {
    width:`2px`,
    style: `solid`,
    color: `${colors.border}`,
    radius: `4px`,
}
/* Buttons */
export const buttons = {}
/* Form */
export const form = {}
/* Nav */
export const nav = {
    height:`90px`
}