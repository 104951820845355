import React from "react"
import background from '../../images/background.jpg'
import Navbar from '../navbar'
import { S } from './style'

export default ({ ...props}) =>{
    return(
        <S.Header>
            <Navbar {...props}/>
            <S.Background url={background}>
                <S.Title>Professional Paintless Dent Repair</S.Title>
                <S.SubTitle>Removing Dents, Dings and Hail Damage!</S.SubTitle>
            </S.Background>
        </S.Header>
    )
}
