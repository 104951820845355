import React from 'react'
import { S } from './style'
import content from '../../../data/content'

export default () => {
    const { 
        services:{
            services
        }
    } = content;

    return(
        <S.Wrapper>
            {
                services.map((node)=> {
                    return(
                    <S.Container>
                        <h1>{node.title}</h1>
                        <p>{node.text}</p>
                        <S.Img src={node.img}/>
                    </S.Container>
                    )
                })
            }
        </S.Wrapper>
    )
} 