import styled from 'styled-components'

export const S = {};

S.Layout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`
S.Children = styled.div`
    flex-grow: 1;
`