import React from "react"
import Header from '../components/header'
import Footer from '../components/footer'
import GlobalStyle from '../styles/GlobalStyle'
import { useSiteMetadata } from '../graphql/useMetadata'
import { S } from './styles' 

export default ({children}) => {
    
    const { defaultTitle, menuLinks } = useSiteMetadata()

    return(
        <S.Layout>
            <GlobalStyle />
            <Header menuLinks={menuLinks} siteTitle={defaultTitle} />
            <S.Children>
                {children}
            </S.Children>
            <Footer />
        </S.Layout>
    )
}
