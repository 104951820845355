import React from 'react'
import { S } from './style'

export default (props) => {
    return(
        <S.Wrapper>
            <S.Heading>
                <h1>{props.title}</h1>
                <p>{props.text}</p>
            </S.Heading>
            {props.img ? <S.Img src={props.img}/> : ''}
        </S.Wrapper>
    )
}