import React from 'react'
import logo from '../../images/logo.svg'
import { Link } from 'gatsby'
import { S } from './style'

export default ({...props}) => {

    return(
        <S.Navigation>
            <Link to="/"><S.NavLogo src={logo}/></Link>
            
            <S.Menu type="checkbox"/>
            <S.Bar />
            <S.Bar />
            <S.Bar />
            <S.Ul>
                <Link to="/"><S.Logo src={logo}/></Link>
                
                {
                    props.menuLinks.map(link  =>
                        <Link key={link.name} to={link.link}>
                            <S.Li >{link.name}</S.Li>
                        </Link>
                    )
                }
                <a href="tel:2104542719"><S.Button>Call Today</S.Button></a>
                
            </S.Ul>
        </S.Navigation>
    )
}